<template>
    <div class="header-bug-report relative overflow-hidden">
        <BaseButton color="third" variant="text" size="sm" @click="showBugForm" v-tooltip:bottom="$t('bugForm.reportProblem')">
            <template #icon>
                <BaseIcon name="bug" class="h-[24px] w-[24px]"></BaseIcon>
            </template>
        </BaseButton>
    </div>
</template>
<script setup>
import { getCurrentInstance } from 'vue';

const instance = getCurrentInstance();
const modal = instance.appContext.config.globalProperties.$modal;
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

function showBugForm() {
    const params = {
        id: 'BugReportForm',
        componentTitle: t('bugForm.reportProblem'),
        name: 'BugReportForm',
        props: {},
        stick: {
            minw: 490,
            resizable: true,
            width: 490,
            left: 975,
            top: 56,
        }
    };
    modal.open(params);
}
</script>
