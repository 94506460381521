import { defineStore } from 'pinia';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import userApi from '../api/users';
import { app } from '@/main.ts';
import dayjs from 'dayjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const baseURL = process.env.VUE_APP_API_URL;

export const useUserStore = defineStore('user', {
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')),
        token: localStorage.getItem('token'),
        WStoken: localStorage.getItem('WStoken'),
        socketId: null,
        returnUrl: null,
        accounts: JSON.parse(localStorage.getItem('accounts')),
        currentAccountId: localStorage.getItem('current_account_id'),
        fingerprint: localStorage.getItem('fringerprint'),
        availibleUser: [],
        isMobile: false,
        // eslint-disable-next-line no-undef
        cookiesToken: $cookies.get('token')
        // deviceUA: null
    }),

    getters: {
        isAuthorize: state => (state.token && state.user ? true : false),
        userRoles: state => (state.user?.roles ? state.user.roles : null),
        isAdmin: state => (state.user?.roles && state.user.roles.includes('superuser') ? true : false),
        isLoyaltyAdmin: state => (state.user?.roles && state.user.roles.includes('loyalty_admin') ? true : false),
        currentLocale: state => (state.user?.lang_id ? state.user?.lang_id : 'en_US'),
        currentUserId: state => state.user?.id
    },
    actions: {
        async getCurrentUserInfo() {
            if (!this.user?.id) return;
            this.user = await userApi.getCurrentUser(this.user.id);
        },
        getEnvironmentByName(name) {
            if (this.accounts && this.accounts && this.currentAccountId) {
                let currentAccountIndex = this.accounts.findIndex(el => el.id === this.currentAccountId);
                if (currentAccountIndex >= 0) {
                    let env = this.accounts[currentAccountIndex].parameters.find(el => el.name === name);
                    if (env) {
                        return env.value;
                    } else {
                        console.warn(`No env for name ${name}`);
                        this.logout();
                    }
                }
            } else this.getAccounts();
        },
        async getAsyncEnvironmentByName(name) {
            if (!this.accounts) {
                await this.getAccounts();
            }
            let env = this.searchEnvironmentByName(name);
            if (!env) {
                await this.getAccounts();
                env = this.searchEnvironmentByName(name);
                if (!env) {
                    console.error(`No env for name ${name}`);
                    return null;
                }
            }
            return env.value;
        },
        searchEnvironmentByName(name) {
            let account = this.accounts.find(el => el.id === this.currentAccountId);
            if (!account) return null;
            return account.parameters.find(el => el.name === name);
        },
        setToken(token) {
            this.token = token;
            localStorage.setItem('token', token);
        },
        setWSToken(token) {
            this.WStoken = token;
            localStorage.setItem('WStoken', token);
        },
        setSocketID(id) {
            this.socketId = id;
        },
        setSpaceId(token) {
            this.account_id = token;
            localStorage.setItem('account_id', token);
        },
        setAccounts(data) {
            this.accounts = data;
            localStorage.setItem('accounts', JSON.stringify(data));
        },
        setCurrentAccountId(id) {
            this.currentAccountId = id;
            localStorage.setItem('current_account_id', id);
        },
        setReturnUrl(returnUrl) {
            this.returnUrl = returnUrl;
        },
        setUser(data) {
            this.user = data;
            localStorage.setItem('user', JSON.stringify(data));
        },
        updateCurrentUserInfo(data) {
            if (data && Object.keys(data)?.length > 0) {
                for (const [key, value] of Object.entries(data)) {
                    this.user[key] = value;
                }
            }
            localStorage.setItem('user', JSON.stringify(this.user));
        },
        async getAvailibleUser() {
            if (this.availibleUser.length <= 0) {
                this.availibleUser = await userApi.getAvailibleUser(this.user?.groups);
            }
        },
        async getAccounts() {
            let res = await userApi.getAccounts();
            if (res?.length) {
                this.setAccounts(res);
                this.setCurrentAccountId(res[0].id);
            }
        },
        async findUsers(ids) {
            let res = [];
            if (Array.isArray(ids)) {
                for (let id of ids) {
                    let el = this.findLocalUser(id);
                    if (el) {
                        res.push(el);
                    } else {
                        el = await this.loadUserById(id);
                        this.availibleUser.push(el[0]);
                        res.push(el[0]);
                    }
                }
            } else {
                let el = this.findLocalUser(ids);
                if (el) {
                    res.push(el);
                } else {
                    el = await this.loadUserById(ids);
                    res.push(el[0]);
                    this.availibleUser.push(el[0]);
                }
            }
            return res;
        },
        findLocalUser(id) {
            if (!Array.isArray(this.availibleUser)) {
                return null;
            }
            let res = this.availibleUser.find(el => el?.id == id);
            return res ? res : null;
        },
        async loadUserById(id) {
            return await userApi.findUserById(id);
        },
        async login(jwt) {
            let jwtData = jwt_decode(jwt);
            this.setToken(jwt);
            let user = {
                id: jwtData.user_id,
                email: jwtData.email,
                first_name: jwtData?.name.split(' ')[0],
                last_name: jwtData?.name.split(' ')[1],
                name: jwtData.name,
                photo_url: jwtData.photo_url,
                groups: jwtData.groups,
                roles: jwtData.roles,
                lang_id: jwtData.lang_id,
                is_active: jwtData.is_active,
                sync_status: jwtData.sync_status
            };

            if (!jwtData?.work_position || !Object.prototype.hasOwnProperty.call(jwtData, 'is_active') || !jwtData.sync_status) {
                const additionalFields = await userApi.getCurrentUser(jwtData.user_id);
                if (additionalFields) {
                    if (additionalFields?.work_position) user.work_position = additionalFields.work_position;
                    if (additionalFields?.is_active !== null) user.is_active = additionalFields.is_active;
                    if (additionalFields?.sync_status !== null) user.sync_status = additionalFields.sync_status;
                }
            }
            this.setUser(user);
            this.checkCurrentLocale();
            await this.getAccounts();
            if (this.returnUrl) {
                this.router.push(this.returnUrl);
            } else this.router.push('/');
        },
        async logout() {
            this.user = null;
            this.token = null;
            this.WStoken = null;
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('WStoken');
            localStorage.removeItem('accounts');
            localStorage.removeItem('current_account_id');
            this.router.push({
                name: 'Login'
            });
            axios.defaults.withCredentials = true;
            await axios.delete(`${baseURL}/auth/logout`, {
                skipAuthRefresh: true
            });
        },
        checkTokenValidity() {
            if (!this.token) return false;
            const decodedToken = jwt_decode(this.token);
            const expTime = dayjs.unix(decodedToken.exp);
            return dayjs().isBefore(expTime);
        },
        refreshToken() {
            axios.defaults.withCredentials = true;
            axios.put(`${baseURL}/auth/refresh`).then(resp => {
                console.log(resp);
            });
        },

        checkCurrentLocale() {
            let saveadLocale = localStorage.getItem('lang');
            if (saveadLocale?.length < 4) {
                localStorage.setItem('lang', 'en_US');
            }
            if (
                this.user?.lang_id.length > 4 &&
                saveadLocale !== this.user?.lang_id &&
                // app?.__VUE_I18N__?.global?.locale
                app?.config?.globalProperties?.$i18n?.locale
            ) {
                // app.__VUE_I18N__.global.locale = this.user?.lang_id?.split("_")[0];
                app.config.globalProperties.$i18n.locale = this.user?.lang_id?.split('_')[0];
                localStorage.setItem('lang', this.user?.lang_id);
            }
        },
        async getFingerprint() {
            if (!this.fingerprint) {
                await this.generateFingerprint();
            }
            return this.fingerprint;
        },
        async generateFingerprint() {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            this.fingerprint = result.visitorId;
            localStorage.setItem('fingerprint', this.fingerprint);
        },
        setisMobile(value) {
            this.isMobile = value;
        },
        setDeviceInfo(value) {
            this.deviceUA = value;
        },
        async checkTimezone() {
            if (this.user && !this.user?.timezone) {
                const user = await userApi.getCurrentUser(this.user.id);
                if (user?.timezone) return
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                this.updateCurrentUserInfo({ timezone: timezone });
                await userApi.updateUser(this.user.id, { timezone: timezone });
            }
        },
    }
});
